import { Skeleton, Pagination } from 'antd';
import { ToastContainer } from 'react-toastify';
import Header from '../../Components/Header';
import MainHeader from '../../Components/MainHeader';
import { GetList } from '../../Config/ApiHandling';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import EditButton from '../../Components/EditButton';
import Copyright from '../../Components/Copyright';

const HomeSlider = ({ setCollapsed, collapsed }) => {
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Track total pages
    const [report, setReport] = useState('daily');
    
    const PAGE_SIZE = 10; // Number of items per page

    const navigate = useNavigate();

    const cols = [
        { id: "bookingID", name: "ID", minWidth: 130 },
        { id: "user_firstName", name: "First Name", minWidth: 130 },
        { id: "bookingdate", name: "Booking Date", minWidth: 130 },
        { id: "bookingAmount", name: "Booking Amount", minWidth: 130 },
        { id: "service_cat", name: "Service Category", minWidth: 130 },
        { id: "staffName", name: "Staff Name", minWidth: 130 },
        { id: "status", name: "Status", minWidth: 130 },
    ];

    const token = localStorage.getItem("token");

    const GetData = useCallback(() => {
        const offset = 1;
        GetList(`https://backend.beautyparkbp.com/api/admin/getAllBookings?type=${report}&page=${currentPage}&limit=${PAGE_SIZE}`, token)
            .then((res) => {
                setData(res.data.data);
                setTotalPages(Math.ceil(res.data.total / PAGE_SIZE)); // Calculate total pages
                setFlag(false);
            })
            .catch((e) => {
                setFlag(false);
            });
    }, [token, report, currentPage]);

    useEffect(() => {
        GetData();
    }, [GetData]);

    const handleEdit = (item) => {
        navigate(`/dashboard/edithomesliderimages`, {
            state: item
        });
    };
    const renderPaginationButtonsConfirm = () => {
        const buttons = [];
        const maxButtonsToShow = 5;
        const halfWay = Math.floor(maxButtonsToShow / 2);
    
        let startPage, endPage;
    
        if (totalPages <= maxButtonsToShow) {
          startPage = 1;
          endPage = totalPages;
        } else if (currentPage <= halfWay) {
          startPage = 1;
          endPage = maxButtonsToShow;
        } else if (currentPage + halfWay >= totalPages) {
          startPage = totalPages - maxButtonsToShow + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - halfWay;
          endPage = currentPage + halfWay;
        }
    
        for (let i = startPage; i <= endPage; i++) {
          buttons.push(
            <li
              key={i}
              className={`page-item ${currentPage === i ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(i)}
                style={{
                  backgroundColor: currentPage === i ? "white" : "#212529",
                  border: "1px solid #212529",
                  borderRadius: "5px",
                  color: currentPage === i ? "black" : "white",
                }}
              >
                {i}
              </button>
            </li>
          );
        }
    
        return buttons;
      };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div>
                <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
                <Header screenTitle="Financial Report" />
                <ToastContainer />
                <div className='row px-3 d-flex justify-content-around'>
                    <div className='col-md-4 mb-4 w-100'>
                        <div className='dash-header-des text-center mt-3'>
                            <h1 className='dasboard-box-text'>Select Financial Report</h1>
                            <hr />
                            <select value={report} onChange={(e) => setReport(e.target.value)} name="salon" id="salonSelect" className='saloon-list'>
                                <option value="" disabled>
                                    Select Financial Report
                                </option>
                                <option value="daily">
                                    Daily Report
                                </option>
                                <option value="weekly">
                                    Weakly Report
                                </option>
                                <option value="monthly">
                                    Monthly Report
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {flag ? (
                        <table
                            className="table table-striped table-bordered text-center"
                            style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                        >
                            <thead>
                                <tr>
                                    {cols.map((item, index) => (
                                        <th className='header-title' style={{ backgroundColor: "#212529", color: "#fff" }} key={index}>{item.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="7">
                                        <Skeleton.Input style={{ width: '90%' }} active animation="wave" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : data.length > 0 ? (
                        <>
                            <table
                                className="table table-striped table-bordered text-center"
                                style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                            >
                                <thead>
                                    <tr>
                                        {cols.map((item, index) => (
                                            <th className='header-title' style={{ backgroundColor: "#212529", color: "#fff" }} key={index}>{item.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((rowData, key) => (
                                        <tr key={key} className='table_img tablerow'>
                                            <td>{rowData.bookingID}</td>
                                            <td>{rowData.user.firstname}</td>
                                            <td>{rowData.bookingdate}</td>
                                            <td>{rowData.bookingAmount}</td>
                                            <td>{rowData.service.service_cat}</td>
                                            <td>{rowData.staff.first_name}</td>
                                            <td>{rowData.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        
                            </div>
                            
                        </>
                    ) : (
                        <table
                            className="table table-striped table-bordered text-center"
                            style={{ width: '90%', margin: '1%', border: '1px solid #ccc' }}
                        >
                            <thead>
                                <tr>
                                    {cols.map((item, index) => (
                                        <th className='header-title' style={{ backgroundColor: "#212529", color: "#fff" }} key={index}>{item.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="7">No Data</td>
                                </tr>
                            </tbody>
                            
                        </table>
                        
                        
                    )}
                </div>
                <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center gap-2  ">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      style={{ color: "black" }}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {renderPaginationButtonsConfirm()}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      style={{ color: "black" }}
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            </div>
            <Copyright />
        </>
    );
};

export default HomeSlider;
