import { Typography, Skeleton } from "antd";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { Box, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import BsButton from "../../Components/BsButton";
import { useNavigate, useParams } from "react-router-dom";
import { Put, GetById, imageurl } from "../../Config/ApiHandling";
import DeleteButton from "../../Components/DeleteButton";
import Copyright from "../../Components/Copyright";

const EditRule = ({ setCollapsed, collapsed }) => {
  const [model, setModel] = useState({
    title: "",
    description: "",
    policy: "",
    text_arb: "",
    text: "",
    description_arb: "",
  });
  const [loading, setLoading] = useState(true);
  let [policy, setPolicy] = useState([]);
  let [policyArb, setPolicyArb] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  let [description, setDescription] = useState([]);
  let [descriptionArb, setDescriptionArb] = useState([]);
  let [howitwork, sethowitwork] = useState([]);
  let [howitworkArb, setHowitworkArb] = useState([]);
  let [condition, setCondition] = useState([]);
  let [conditionArb, setConditionArb] = useState([]);
  const [formValues, setFormValues] = useState({
    images: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  let token = localStorage.getItem("token");

  useEffect(() => {
    const page = localStorage.getItem("pagename");
    const endpoints =
      page === "Privacy Policy"
        ? "getPolicybyId"
        : page === "About Us"
        ? "getAboutusbyId"
        : page === "Terms & Condition"
        ? "getconditionById"
        : page === "How it Work"
        ? "howitworkById"
        : "";
    GetById(endpoints, params.id, token)
      .then((res) => {
        sethowitwork(res?.data?.data?.text);
        setHowitworkArb(res?.data?.data?.text_arb);
        setCondition(res.data.data?.condition);
        setConditionArb(res.data.data?.condition_arb);
        setDescription(res?.data?.data?.description);
        setPolicy(res?.data?.data?.policy);
        setDescriptionArb(res?.data?.data?.description_arb);
        setPolicyArb(res?.data?.data?.policy_arb);
        setModel(res?.data?.data);
        setImage(res?.data?.data?.images?.at(0));
        setLoading(false);
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      });
  }, [params?.id,token]);

  const handleQuillChange = (value) => {
    if (model.title === "Privacy Policy") {
      setPolicy(value);
    } else if (model.title === "About Us") {
      setDescription(value);
    } else if (model.title === "Terms & Condition") {
      setCondition(value);
    } else if (model.title === "How it Work") {
      sethowitwork(value);
    }
  };

  const handleQuillChangeArb = (value) => {
    if (model.title === "Privacy Policy") {
      setPolicyArb(value);
    } else if (model.title === "About Us") {
      setDescriptionArb(value);
    } else if (model.title === "Terms & Condition") {
      setConditionArb(value);
    } else if (model.title === "How it Work") {
      setHowitworkArb(value);
    }
  };

  const handleTitleChange = (e) => {
    setModel((prevModel) => ({
      ...prevModel,
      title: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const newImageURL = URL.createObjectURL(file);
      setSelectedImage(newImageURL);
      setFormValues({ ...formValues, images: file });
    } else {
      setSelectedImage(null);
      setFormValues({ ...formValues, images: null });
    }
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    const endpoint =
      model?.title === "Privacy Policy"
        ? "updateprivacypolicy"
        : model?.title === "About Us"
        ? "updateAboutus"
        : model?.title === "Terms & Condition"
        ? "updatecondition"
        : model?.title === "How it Work"
        ? "updateHowitwork"
        : "";

    const formData = new FormData();
    if (model.title === "Privacy Policy") {
      formData.append("title", model.title);
      formData.append("policy", policy);
      formData.append("policy_arb", policyArb);
    } else if (model.title === "About Us") {
      formData.append("title", model.title);
      formData.append("description", description);
      formData.append("description_arb", descriptionArb);
      formData.append("images", formValues.images);
    } else if (model.title === "How it Work") {
      formData.append("title", model.title);
      formData.append("text", howitwork);
      formData.append("text_arb", howitworkArb);
    } else if (model.title === "Terms & Condition") {
      formData.append("title", model.title);
      formData.append("condition", condition);
      formData.append("condition_arb", conditionArb);
    }
    // const updatedModel = {
    //     ...model,
    //     description: model.title === 'Privacy Policy' ? policy : description,
    //     policy: model.title === 'Privacy Policy' ? policy : description,
    //     images: formData,
    // };
    Put(endpoint, model._id, formData, token)
      .then((res) => {
        setLoading(false);
        toast.success("Updated SuccessFully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
            navigate("/dashboard/pagelist");
        }, 1000);
        GetById(
          endpoint === "updateprivacypolicy"
            ? "getPolicybyId"
            : "getAboutusbyId",
          params.id,
          token
        )
          .then((res) => {
            setDescription(res.data.data.description);
            setDescriptionArb(res.data.data.description_arb);
            setPolicy(res.data.data.policy);
            setPolicyArb(res.data.data.policy_arb);
            setModel(res.data.data);
          })
          .catch((err) => {
            const errorMessage =
              err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : "An error occurred";
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response && error.response.data) {
          const { status, data, message } = error.response.data;
          if (status === false && data === null && message) {
            toast.error(`Error: ${message}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        }
      });
  };

  return (
    <div>
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header
        screenTitle="Edit Page"
        buttonList={[
          {
            displayField: () => (
              <DeleteButton
                click={() => navigate("/dashboard/pagelist")}
                text="Cancel"
              />
            ),
          },
        ]}
      />
      <Box
        sx={{ width: { md: "98%", sm: "325px", xs: "100%" } }}
        className="shadow m-auto mt-2"
      >
        <Box>
          <ToastContainer />
          {loading ? (
            <div className="mt-2">
              <Box className="row p-3">
                <div>
                  <Typography variant="p"> Title: </Typography>
                  <div className="mt-2">
                    <Skeleton.Input active style={{ width: "90vh" }} />
                  </div>
                </div>
                <div className="my-4">
                  <Typography variant="p"> Description (Eng) : </Typography>
                  <div className="mt-2">
                    <Skeleton.Input
                      active
                      style={{ width: "90vh", height: "60vh" }}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <Typography variant="p"> Description (Arb) : </Typography>
                  <div className="mt-2">
                    <Skeleton.Input
                      active
                      style={{ width: "90vh", height: "60vh" }}
                    />
                  </div>
                </div>
                {model.title === "About Us" && (
                  <div className="my-4">
                    <Typography variant="p"> Image : </Typography>
                    <div className="mt-2">
                      <Skeleton.Input active style={{ width: "90vh" }} />
                    </div>
                  </div>
                )}
                <Box
                  sx={{ display: "flex", justifyContent: "start" }}
                  className="  py-3"
                >
                  <Skeleton.Button
                    active
                    shape="button"
                    style={{ width: "20vh" }}
                  />
                </Box>
              </Box>
            </div>
          ) : (
            <Box className="row p-3">
              <div>
                <label className="mx-1" htmlFor="titleInput">
                  Ttile:
                </label>
                <div className="mt-2">
                  <input
                    id="titleInput"
                    className="form-control w-100"
                    defaultValue={model?.title}
                    type="text"
                    disabled={true}
                    onChange={handleTitleChange}
                  />
                </div>
              </div>

              <div className="my-4">
                <Typography className="mx-1" variant="p">
                  Text (Eng) :
                </Typography>
                <div className="mt-2">
                  <ReactQuill
                    value={
                      model?.title === "Privacy Policy"
                        ? policy
                        : model?.title === "About Us"
                        ? description
                        : model?.title === "Terms & Condition"
                        ? condition
                        : model?.title === "How it Work"
                        ? howitwork
                        : ""
                    }
                    onChange={handleQuillChange}
                  />
                </div>
              </div>
              <div className="my-4">
                <Typography className="mx-1" variant="p">
                  Text (Arb) :
                </Typography>
                <div className="mt-2">
                  <ReactQuill
                    value={
                      model?.title === "Privacy Policy"
                        ? policyArb
                        : model?.title === "About Us"
                        ? descriptionArb
                        : model?.title === "Terms & Condition"
                        ? conditionArb
                        : model?.title === "How it Work"
                        ? howitworkArb
                        : ""
                    }
                    onChange={handleQuillChangeArb}
                  />
                </div>
              </div>
              {model.title === "About Us" && (
                <div className="col-md-6">
                  <Typography variant="p"> Image : </Typography>
                  <div className="mt-2">
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleFileChange(e)}
                    />
                    <Button
                      component="label"
                      className="form-control w-100"
                      htmlFor="fileInput"
                    >
                      Select Image
                    </Button>
                    {image && (
                      <div>
                        {formValues?.images instanceof File ? (
                          <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ width: "100px", marginTop: "10px" }}
                          />
                        ) : (
                          <img
                            src={`${imageurl(image).imageURL}`}
                            style={{ width: "100px", marginTop: "10px" }}
                            alt=""
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <Box
                sx={{ display: "flex", justifyContent: "start" }}
                className="py-3"
              >
                <BsButton
                  text={"Update"}
                  onClick={handleSubmit}
                  loading={loading}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Copyright />
    </div>
  );
};

export default EditRule;
