import MainHeader from "../../Components/MainHeader";
import React, { useEffect, useRef, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { GetList, GetTheList, Post } from "../../Config/ApiHandling";
import Copyright from "../../Components/Copyright";
import { toast, ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Header from "../../Components/Header";

function BookingsList({ setCollapsed, collapsed }) {
  let [saloonList, setSaloonList] = useState();
  let [confirmBookings, setConfirmBookings] = useState();
  let [rejectedBookings, setRejectedBookings] = useState();
  const [selectedSalonId, setSelectedSalonId] = useState("");
  const [selectedOption, setSelectedOption] = useState("confirm");
  const [bookingdate, setBookingdate] = useState();
  const [showFromDatePicker, setShowFromDatePicker] = useState(false);
  const [showToDatePicker, setShowToDatePicker] = useState(false);
  const [showToDatePicker1, setShowToDatePicker1] = useState(false);
  const [showToDatePicker2, setShowToDatePicker2] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesConfirm, setTotalPagesConfirm] = useState([]);
  const [totalPagesReject, setTotalPagesReject] = useState([]);
  const token = localStorage.getItem("token");
  const calendarRef = useRef(null);
  const calendarRef1 = useRef(null);
  const calendarRef2 = useRef(null);
  const calendarRef3 = useRef(null);
  const selectedSalonIdRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    GetList("partner/getBusiness", token)
      .then((res) => {
        setSaloonList(res?.data?.data?.businesses);
      })
      .catch(() => {});

    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowToDatePicker(false);
      }
      if (
        calendarRef1.current &&
        !calendarRef1.current.contains(event.target)
      ) {
        setShowFromDatePicker(false);
      }
      if (
        calendarRef2.current &&
        !calendarRef2.current.contains(event.target)
      ) {
        setShowToDatePicker1(false);
      }
      if (
        calendarRef3.current &&
        !calendarRef3.current.contains(event.target)
      ) {
        setShowToDatePicker2(false);
      }

    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const parts = formattedDate.split(" ");
    return `${parts[0]}-${parts[1]}-${parts[2]}`;
  };

  const handleFromDateChange = (date) => {
    setConfirmBookings([]);
    const formattedDate = formatDate(date);
    setStartDate(date);
    handleOnChange("date", formattedDate);
    setShowFromDatePicker(false);
  };

  const handleToDateChange = (date) => {
    setRejectedBookings([]);
    const formattedDate = formatDate(date);
    setEndDate(date);
    handleOnChange("date", formattedDate);
    setShowToDatePicker(false);
    setShowToDatePicker1(false)
    setShowToDatePicker2(false)
  };

  const handleOnChange = (key, value) => {
    const updatedBookingDate = {
      ...bookingdate,
      [key]: value,
    };
    setBookingdate(updatedBookingDate);
    searchBookings(updatedBookingDate, null);
  };

  const searchBookings = async (myobj) => {
    if (selectedSalonIdRef.current) {
      Post(
        `admin/onDateBookings/${selectedSalonIdRef.current}/${selectedOption}`,
        myobj,
        token
      )
        .then((res) => {
          const data = res.data.data;
          const confirmed = data.filter((item) => item.status === "confirm");
          const rejected = data.filter((item) => item.status === "rejected");
          setConfirmBookings(confirmed);
          setRejectedBookings(rejected);
        })
        .catch(() => {});
    } else {
      toast.error("Select saloon first");
      setShowToDatePicker(false);
      setShowFromDatePicker(false);
    }
  };

  const handleSalonSelect = (event) => {
    if (event && event.target) {
      const selectedSalonid1 = event.target.value;
      selectedSalonIdRef.current = event.target.value;
      setSelectedSalonId(selectedSalonid1);
      handleSalonSelectData(); // Call handleSalonSelectData without passing selectedSalonId
    }
  };

  const handleSalonSelectData = () => {
    const selectedSalonIdByRef = selectedSalonIdRef.current;
    if (selectedSalonIdByRef) {
      const limit = 10;
      GetTheList(
        `admin/getSalonDetails/${selectedSalonIdByRef}`,
        currentPage,
        limit,
        token
      )
        .then((res) => {
          setConfirmBookings(res?.data?.ConfirmBooking);
          setRejectedBookings(res?.data?.RejectedBooking);
          setTotalPagesConfirm(res?.data?.ConfirmBookingPagination?.totalPages);
          setTotalPagesReject(res?.data?.RejectedBookingPagination?.totalPages);
        })
        .catch((err) => {
          const errorMessage =
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "An error occurred";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const handleBoxSelect = (option) => {
    setSelectedOption(option);
    switch (option) {
      case "confirm":
        break;
      case "rejected":
        break;
      case "complete":
        break;
      case "pending":
        break;
      default:
        break;
    }
  };
  let Ordercolumns = [
    { id: "bookingID", label: "ID", minWidth: 130 },
    { id: "first_name", label: "First Name", minWidth: 130 },
    { id: "bookingdate", label: "Booking Date", minWidth: 130 },
    { id: "Service_cat", label: "Service Category", minWidth: 130 },
    { id: "Staffname", label: "Staff Name", minWidth: 130 },
    { id: "status", label: "Status", minWidth: 130 },
    // { id: "saloonprofit", label: "Saloon Profit", minWidth: 130 },
    // { id: "salooncomission", label: "Saloon Commission", minWidth: 130 },
  ];

  let Orderrows = confirmBookings?.map((item) => ({
    bookingID: item.bookingID,
    first_name: item.firstname,
    bookingdate: item.bookingdate,
    Service_cat: item.service?.service_cat,
    Staffname: item.staff?.first_name,
    status: item.status,
    // saloonprofit: item.saloonprofit, // Assuming there is a field called saloonprofit
    // salooncomission: item.salooncomission, // Assuming there is a field called salooncomission
  }));

  let Orderrows1 = rejectedBookings?.map((item) => ({
    bookingID: item.bookingID,
    first_name: item.firstname,
    bookingdate: item.bookingdate,
    Service_cat: item.service?.service_cat,
    Staffname: item.staff?.first_name,
    status: item.status,
    // saloonprofit: item.saloonprofit, // Assuming there is a field called saloonprofit
    // salooncomission: item.salooncomission, // Assuming there is a field called salooncomission
  }));

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    handleSalonSelectData();
  }, [currentPage]);

  const renderPaginationButtonsConfirm = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPagesConfirm <= maxButtonsToShow) {
      startPage = 1;
      endPage = totalPagesConfirm;
    } else if (currentPage <= halfWay) {
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPagesConfirm) {
      startPage = totalPagesConfirm - maxButtonsToShow + 1;
      endPage = totalPagesConfirm;
    } else {
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  const renderPaginationButtonsReject = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPagesReject <= maxButtonsToShow) {
      startPage = 1;
      endPage = totalPagesReject;
    } else if (currentPage <= halfWay) {
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPagesReject) {
      startPage = totalPagesReject - maxButtonsToShow + 1;
      endPage = totalPagesReject;
    } else {
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };
  return (
    <div className="w-100">
      <ToastContainer />
      <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
      <Header screenTitle="Bookings List" />
      <div className="container">
        <div className='className="row px-3 d-flex justify-content-around"'>
          <div className="col-md-4 mb-4 w-100">
            <div className="dash-header-des text-center mt-3">
              <h1 className="dasboard-box-text">Select Saloon</h1>
              <hr />
              <select
                name="salon"
                id="salonSelect"
                className="saloon-list"
                onChange={handleSalonSelect}
                value={selectedSalonId}
              >
                <option value="" disabled>
                  Select salon
                </option>
                {saloonList &&
                  saloonList.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.partnername_eng}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row pb-4 px-4 d-flex justify-content-center gap-3 main-dashboard-options">
          <div
            className="col-md-4 mb-4 Dashboard-options position-relative"
            style={{
              backgroundColor:
                selectedOption === "confirm" ? "#212529" : "white",
            }}
          >
            <div
              className="py-3 px-0 rounded text-white d-flex justify-content-center"
              onClick={() => handleBoxSelect("confirm")}
            >
              <div className="dash-header-des text-center w-100">
                <span
                  className="dasboard-box-head d-flex justify-content-between"
                  style={{
                    color: selectedOption === "confirm" ? "white" : "black",
                  }}
                >
                  Confirmed Bookings
                  <FaCalendarAlt onClick={() => setShowFromDatePicker(true)} />
                </span>
              </div>
              {showFromDatePicker && (
                <div ref={calendarRef1}>
                  <Calendar
                    onChange={handleFromDateChange}
                    value={startDate}
                    className="custom-calendar1"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="col-md-4 mb-4 Dashboard-options position-relative"
            style={{
              backgroundColor:
                selectedOption === "rejected" ? "#212529" : "white",
            }}
            onClick={() => handleBoxSelect("rejected")}
          >
            <div className="py-3 px-0 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center w-100">
                <span
                  className="dasboard-box-head d-flex justify-content-between"
                  style={{
                    color: selectedOption === "rejected" ? "white" : "black",
                  }}
                >
                  Rejected Bookings
                  <FaCalendarAlt onClick={() => setShowToDatePicker(true)} />
                </span>
              </div>
              {showToDatePicker && (
                <div ref={calendarRef}>
                  <Calendar
                    onChange={handleToDateChange}
                    value={endDate}
                    className="custom-calendar2"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="col-md-4 mb-4 Dashboard-options position-relative"
            style={{
              backgroundColor:
                selectedOption === "complete" ? "#212529" : "white",
            }}
            onClick={() => handleBoxSelect("complete")}
          >
            <div className="py-3 px-0 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center w-100">
                <span
                  className="dasboard-box-head d-flex justify-content-between"
                  style={{
                    color: selectedOption === "complete" ? "white" : "black",
                  }}
                >
                  Complete Bookings
                  <FaCalendarAlt onClick={() => setShowToDatePicker1(true)} />
                </span>
              </div>
              {showToDatePicker1 && (
                <div ref={calendarRef2}>
                  <Calendar
                    onChange={handleToDateChange}
                    value={endDate}
                    className="custom-calendar2"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="col-md-4 mb-4 Dashboard-options position-relative"
            style={{
              backgroundColor:
                selectedOption === "pending" ? "#212529" : "white",
            }}
            onClick={() => handleBoxSelect("pending")}
          >
            <div className="py-3 px-0 rounded text-white d-flex justify-content-center">
              <div className="dash-header-des text-center w-100">
                <span
                  className="dasboard-box-head d-flex justify-content-between"
                  style={{
                    color: selectedOption === "pending" ? "white" : "black",
                  }}
                >
                  Pending Bookings
                  <FaCalendarAlt onClick={() => setShowToDatePicker2(true)} />
                </span>
              </div>
              {showToDatePicker2 && (
                <div ref={calendarRef3}>
                  <Calendar
                    onChange={handleToDateChange}
                    value={endDate}
                    className="custom-calendar2"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {Ordercolumns.length > 0 &&
                      Ordercolumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "rgb(33, 37, 41)",
                            color: "rgb(255, 255, 255)",
                            borderWidth: "0 var(--bs-border-width)",
                            fontSize: "20px !important",
                          }}
                          className="header-title text-center Partnertableheading"
                        >
                          {column.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(selectedOption === "confirm" && Orderrows?.length > 0) ||
                  (selectedOption === "rejected" && Orderrows1?.length > 0) ? (
                    (selectedOption === "confirm"
                      ? Orderrows
                      : Orderrows1
                    )?.map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        className={`${
                          rowIndex % 2 === 0 ? "checkcolor" : "bg-[white]"
                        }`}
                      >
                        {Ordercolumns.length > 0 ? (
                          Ordercolumns.map((column, index) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{
                                borderRight: "2px solid rgb(167 159 159 / 35%)",
                              }}
                            >
                              {row[column.id]}
                            </TableCell>
                          ))
                        ) : (
                          <TableCell>No Data Found</TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={Ordercolumns.length || 1}
                        align="center"
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {confirmBookings &&
          confirmBookings.length > 0 &&
          selectedOption === "confirm" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center gap-2  ">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      style={{ color: "black" }}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {renderPaginationButtonsConfirm()}
                  <li
                    className={`page-item ${
                      currentPage === totalPagesConfirm ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      style={{ color: "black" }}
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPagesConfirm}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          ) : null}
          {rejectedBookings &&
          rejectedBookings.length > 0 &&
          selectedOption === "reject" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center gap-2  ">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      style={{ color: "black" }}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {renderPaginationButtonsReject()}
                  <li
                    className={`page-item ${
                      currentPage === totalPagesReject ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      style={{ color: "black" }}
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPagesReject}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          ) : null}
        </div>
      </div>
      <Copyright />
    </div>
  );
}

export default BookingsList;
