import React, { useEffect, useState } from 'react'
import MainHeader from '../../Components/MainHeader'
import Header from '../../Components/Header'
import { Box } from '@mui/material'
import { Typography, Skeleton } from 'antd'
import { GetById } from '../../Config/ApiHandling'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'


const AdminDetails = ({ setCollapsed, collapsed }) => {
    const [formValues, setFormValues] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        type: '',
    });
    const params = useParams()
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token")

    useEffect(() => {
        GetById("admin/getByID", params.id, token)
            .then((res) => {
                setFormValues(res.data.data)
                setLoading(false);
            })
            .catch((err) => {
                const errorMessage = err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : 'An error occurred';
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            })
    }, [params?.id,token]);

    return (
        <div>
            <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
            <Header screenTitle="Admin Details" />
            <Box sx={{ width: { md: '98%', sm: '325px', xs: '100%' } }} className=' shadow m-auto mt-2'>
                <Box >
                    {loading ? (
                        <div className="mt-2">
                            <Box className='row p-3'>
                                <div className="col-md-6">
                                    <Typography variant='p'> First Name : </Typography>
                                    <div className='mt-2'>
                                        <Skeleton.Input active style={{ width: '40vh' }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Typography variant='p'> Last Name : </Typography>
                                    <div className='mt-2'>
                                        <Skeleton.Input active style={{ width: '40vh' }} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Typography className='mt-5' variant='p'> Email : </Typography>
                                    <div className='mt-2'>
                                        <div className="d-flex">
                                            <Skeleton.Input active style={{ width: '40vh' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Typography className='mt-5' variant='p'> Phone Number : </Typography>
                                    <div className='mt-2'>
                                        <div className="d-flex">
                                            <Skeleton.Input active style={{ width: '40vh' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Typography className='mt-5' variant='p'> Type : </Typography>
                                    <div className='mt-2'>
                                        <div className="d-flex">
                                            <Skeleton.Input active style={{ width: '40vh' }} />
                                        </div>
                                    </div>
                                </div>
                                <Box sx={{ display: 'flex', justifyContent: 'start' }} className='  py-3'>
                                    <Skeleton.Button active shape='button' style={{ width: '20vh' }} />
                                </Box>
                            </Box>
                        </div>
                    ) : (
                        <Box className='row p-3'>
                            <div className="col-md-6">
                                <Typography variant='p'> First Name : </Typography>
                                <div className='mt-2'>
                                    <input
                                        className="form-control w-100"
                                        type="text"
                                        value={formValues.firstname}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Typography variant='p'> Last Name : </Typography>
                                <div className='mt-2'>
                                    <input
                                        className="form-control w-100"
                                        type="text"
                                        value={formValues.lastname}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Typography className='mt-5' variant='p'> Email : </Typography>
                                <div className='mt-2'>
                                    <input
                                        className="form-control w-100"
                                        type="email"
                                        value={formValues.email}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Typography className='mt-5' variant='p'> Phone Number : </Typography>
                                <div className='mt-2'>
                                    <input
                                        className="form-control w-100"
                                        type="text"
                                        value={formValues.phonenumber}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Typography className='mt-5' variant='p'> Type : </Typography>
                                <div className='mt-2'>
                                    <input
                                        className="form-control w-100"
                                        type="text"
                                        value={formValues.type}
                                        disabled
                                    />
                                </div>
                            </div>
                        </Box>
                    )}
                </Box>
            </Box >
        </div >
    )

}

export default AdminDetails