import { Skeleton } from "antd";
import { ToastContainer } from "react-toastify";
// import { div } from '@mui/material'
import { toast } from "react-toastify";
import Header from "../../Components/Header";
import MainHeader from "../../Components/MainHeader";
import { Delete, GetThePage, Post, Put } from "../../Config/ApiHandling";
import Swal from "sweetalert2";
import { useCallback, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInp";
// import DeleteButton from "../../Components/DeleteButton";
import EditButton from "../../Components/EditButton";
import BlockButton from "../../Components/BlockButton";
import { Modal } from "react-bootstrap";
import Copyright from "../../Components/Copyright";
import { useNavigate } from "react-router-dom";
import ApprovedButton from "../../Components/ApprovedButtton";

const PartnerList = ({ setCollapsed, collapsed }) => {
  let [data, setData] = useState([]);
  let [detail, setDetail] = useState([]);
  let [flag, setflag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showComissionModal, setShowComissionModal] = useState(false);
  const [commissionAmount, setCommissionAmount] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [comisionError, setCommisionError] = useState("");
  const [isAdminApprove, setIsAdminApprove] = useState(false);
  // const [userDetails, setUserDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const startIndex = (currentPage - 1) * 10 + 1;
  let navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchComission = (userId, isAdminApprove) => {
    const selectedUser = data.find((user) => user._id === userId);
    const commission = selectedUser ? selectedUser.commision : 0;
    setSelectedPartnerId(userId);
    setCommissionAmount(commission);
    setShowComissionModal(true);
    setIsAdminApprove(isAdminApprove);
  };

  const handleCloseCommissionModal = () => {
    setShowComissionModal(false);
    setSelectedPartnerId(null);
    setCommissionAmount(0);
    setIsAdminApprove(false);
  };

  // const handleShowModal = (userData) => {
  //   setUserDetails(userData);
  //   setShowModal(true);
  // };
  const approvedPromo = (id, amount, isAdminApprove) => {
    if (!amount) {
      setCommisionError("⚠️ Commission is required");
      return;
    }

    const runApprovedPromo = () => {
      Post(`/admin/approvedPartner/${id}`, { commision: amount }, token)
        .then((res) => {
          GetData();
          setShowComissionModal(false);
          setCommissionAmount("");
          setCommisionError("");
        })
        .catch((e) => {
          const errorMessage =
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : "An error occurred";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    };

    if (isAdminApprove) {
      runApprovedPromo();
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved it!",
      })
        .then((result) => {
          if (result.isConfirmed) {
            runApprovedPromo();
          }
        })
        .catch((e) => {
          const errorMessage =
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : "An error occurred";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const fetchPartnerDetails = (userId, item) => {
    setDetail(data.filter((partner) => partner._id === userId));
    const filterdata = data.filter((partner) => partner._id === userId);
    // console.log(filterdata);
    navigate(`/dashboard/partnerdetail/${filterdata[0]?._id}`);
    // setShowModal(true);
  };

  let cols = [
    {
      name: "Sr No",
      key: "index",
    },
    {
      name: "First Name",
      displayname: (item) => (item?.firstname ? item?.firstname : item?.name),
    },
    // {
    //   name: "Last Name",
    //   displayname: (item) => (item?.lastname ? item?.lastname : '---'),
    // },
    {
      name: "Email",
      key: "email",
    },
    // {
    //   name: "Number",
    //   key: "phonenumber",
    // },
    {
      name: "Action",
      displayFeild: (item) => (
        <>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <EditButton text="" click={() => fetchPartnerDetails(item._id)} />
            <ApprovedButton
              disabled={item.isAdminApprove}
              text="Approved"
              click={() => fetchComission(item._id, item.isAdminApprove)}
            />

            <BlockButton
              text={`${
                item?.isBlock === "true" || item?.isBlock === true
                  ? "unSuspend"
                  : "Suspend"
              }`}
              click={() => BlockPartner(item._id, item.isBlock)}
            />
            {/* <DeleteButton text="Delete" click={() => DeletePartner(item._id)} /> */}
          </div>
        </>
      ),
    },
  ];

  // const renderItem = (col, item) => {
  //     if (col.displayname) {
  //         return col.displayname(item);
  //     }
  //     return item[col.key];
  // };

  const BlockPartner = (userId, blocked) => {
    const token = localStorage.getItem("token");
    const respo = blocked === "true" || blocked === true ? false : true;
    Swal.fire({
      title: "Are you sure?",
      text: ` You want to ${respo ? "Block" : "Unblocked"} this partner ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${respo ? "Block" : "Unblock"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        Put(
          "partner/unblock",
          userId,
          {
            isBlock: respo,
          },
          token
        )
          .then((res) => {
            toast(`Partner ${respo ? "Blocked" : "Unblocked"} successfully`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setData((prevData) => {
              const updatedData = prevData.map((partner) =>
                partner._id === userId
                  ? { ...partner, isBlock: respo }
                  : partner
              );
              return updatedData;
            });
          })
          .catch((error) => {
            const errorMessage =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : "An error occurred";
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
        Swal.fire({
          title: `${respo ? "Blocked" : "Unblocked"}!`,
          text: `Your partner has been ${respo ? "Blocked" : "Unblocked"}.`,
          icon: "success",
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Your action has been cancelled.",
          icon: "error",
        });
      }
    });
  };

  const token = localStorage.getItem("token");
  // const GetData = () => {
  //   setflag(true);
  //   const limit = 10;
  //   GetThePage("admin/getuserbytype", "partner", currentPage, limit, token)
  //     .then((res) => {
  //       setData(res.data.data.users);
  //       setTotalPages(res.data.data.totalPages);
  //     })
  //     .catch((e) => {
  //       const errorMessage =
  //         e.response && e.response.data && e.response.data.message
  //           ? e.response.data.message
  //           : "An error occurred";
  //       toast.error(errorMessage, {
  //         position: "top-right",
  //         autoClose: 4000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     })
  //     .finally(() => {
  //       setflag(false);
  //     });
  // };

  // useEffect(() => {
  //   GetData(currentPage);
  // }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const GetData = useCallback(() => {
    setflag(true);
    const limit = 10;
    GetThePage("admin/getuserbytype", "partner", currentPage, limit, token)
      .then((res) => {
        setData(res.data.data.users);
        setTotalPages(res.data.data.totalPages);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .finally(() => {
        setflag(false);
      });
  }, [currentPage, token]);

  useEffect(() => {
    GetData(); // Call GetData directly here
  }, [GetData]);

  let DeletePartner = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      ?.then((result) => {
        if (result.isConfirmed) {
          Delete("partner/delete", e, token)
            ?.then((res) => {
              GetData();
            })
            .catch((e) => {
              const errorMessage =
                e.response && e.response.data && e.response.data.message
                  ? e.response.data.message
                  : "An error occurred";
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            });
        }
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "An error occurred";
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white",
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="Partner List" />
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            width: "100%",
            margin: "0px -40px",
          }}
        >
          <SearchInput data={data} setData={setData} originalData={GetData} />
        </div>
        <ToastContainer />
        <>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdrop="true"
            keyboard={false}
            dialogClassName="custom-modal"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Partner Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <>
                  {/* {console.log(detail[0]?.firstname?detail[0]:detail)} */}
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>First Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={
                            detail[0]?.firstname
                              ? detail[0]?.firstname
                              : detail[0]?.name
                          }
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Last Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={
                            detail[0]?.lastname ? detail[0]?.lastname : "---"
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Email: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.email}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Type: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.type}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Buisness Name: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.businessname}
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Buisness Type: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.businesstype_1}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h6>Phone Number: </h6>{" "}
                        <input
                          type="text"
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "100%",
                          }}
                          value={detail[0]?.phonenumber}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
          <Modal
            show={showComissionModal}
            onHide={handleCloseCommissionModal}
            centered
            backdrop="true"
            keyboard={false}
            dialogClassName="custom-modal"
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title className="dashboard-commission-modal">
                Commission
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail && (
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h6>% Amount: </h6>
                      <input
                        type="number"
                        value={commissionAmount}
                        onChange={(e) => setCommissionAmount(e.target.value)}
                        style={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          padding: "8px",
                          width: "100%",
                        }}
                        className="no-spinner"
                      />
                      {commissionAmount ? null : <p>{comisionError}</p>}
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    approvedPromo(
                      selectedPartnerId,
                      commissionAmount,
                      isAdminApprove
                    )
                  }
                >
                  Save changes
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {flag ? (
              <>
                <table
                  className="table table-striped table-bordered text-center"
                  style={{
                    width: "90%",
                    margin: "1%",
                    border: "1px solid #ccc",
                  }}
                >
                  <thead>
                    <tr>
                      {cols &&
                        cols?.map((item, index) => {
                          return (
                            <th
                              className="header-title"
                              style={{
                                backgroundColor: "#212529",
                                color: "#fff",
                              }}
                              key={index}
                            >
                              {item.name}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {cols?.map((item, index) => (
                      <tr key={index}>
                        <td key={index} colSpan="6">
                          <Skeleton.Input
                            style={{ width: "90vh" }}
                            active
                            animation="wave"
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : data && data.length > 0 ? (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols?.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, key) => (
                    <tr key={key} className="table_img tablerow">
                      {cols?.map((col, index) => (
                        <td style={{ verticalAlign: "middle" }} key={index}>
                          {/* Using col.key */}
                          {col.displayFeild ? (
                            col.displayFeild(item)
                          ) : col.key === "index" ? (
                            startIndex + key
                          ) : col.img ? (
                            <img
                              className="table_img"
                              src={`${item[item.img]}`}
                              alt="NoImage"
                              style={{ width: "60px", height: "55px" }}
                            />
                          ) : col.key === "amount" || col.key === "price" ? (
                            `${item[col.key]}`
                          ) : col.displayname ? (
                            col.displayname(item)
                          ) : col.displayname ? (
                            col.displayname(item)
                          ) : col.key === "email" ? (
                            item?.email
                          ) : col.key === "phonenumber" ? (
                            item?.phonenumber
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%", border: "1px solid #ccc" }}
              >
                <thead>
                  <tr>
                    {cols &&
                      cols.map((item, index) => {
                        return (
                          <th
                            className="header-title"
                            style={{
                              backgroundColor: "#212529",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            {item.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="6">No Data</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2  ">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                      backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                      border: "1px solid #212529",
                      borderRadius: "5px",
                      color: currentPage === index + 1 ? "black" : "white"
                  }}
                  >
                    {index + 1}
                  </button>
                </li>
              ))} */}
              {renderPaginationButtons()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  style={{ color: "black" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default PartnerList;
