// import { Box } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import { ToastContainer } from 'react-bootstrap'
// import Header from '../../Components/Header'
// import MainHeader from '../../Components/MainHeader'
// import Table from '../../Components/Table'
// import { Delete, GetThePage } from '../../Config/ApiHandling'
// import { useNavigate } from 'react-router-dom'
// import Swal from 'sweetalert2'
// import { toast } from 'react-toastify'
// import SearchInput from '../../Components/SearchInp'
// import AddButton from '../../Components/AddButton'
// import EditButton from '../../Components/EditButton'
// import DeleteButton from '../../Components/DeleteButton'
// import Copyright from '../../Components/Copyright'

// const ListAdmin = ({ setCollapsed, collapsed }) => {
//   // let [open, setopen] = useState(false)
//   let [data, setdata] = useState([])
//   let [flag, setflag] = useState(false)
//   let navigation = useNavigate()
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [searchError, setSearchError] = useState(false);


//   const token = localStorage.getItem("token")
//   const Getdata = () => {
//     setflag(true);
//     const limit = 10;

//     GetThePage(`admin/getuserbytype`, 'admin', currentPage, limit, token)
//       .then((res) => {
//         setdata(res.data.data.users);
//         setTotalPages(res.data.data.totalPages);
//       })
//       .catch((e) => {
//         const errorMessage = e.response && e.response.data && e.response.data.message
//           ? e.response.data.message
//           : 'An error occurred';
//         toast.error(errorMessage, {
//           position: "top-right",
//           autoClose: 4000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       })
//       .finally(() => {
//         setflag(false);
//       });
//   };

//   useEffect(() => {
//     Getdata(currentPage)
//   }, [currentPage]);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   let edit = (e) => {
//     navigation(`/dashboard/editadmin/${e._id}`, {
//       state: e
//     }
//     )
//   }


//   let DeleteRow = (e) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     })
//       .then((result) => {
//         if (result.isConfirmed) {
//           Delete("/admin/delete", e._id, token)
//             .then((res) => {
//               Getdata()
//               let filter = data.filter((x) => x._id !== e._id);
//               setdata([...filter]);
//             })
//             .catch((error) => {
//               const errorMessage = error.response && error.response.data && error.response.data.message
//                 ? error.response.data.message
//                 : 'An error occurred';
//               toast.error(errorMessage, {
//                 position: "top-right",
//                 autoClose: 4000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "light",
//               });
//             });
//         }
//       })
//       .catch((error) => {
//         const errorMessage = error.response && error.response.data && error.response.data.message
//           ? error.response.data.message
//           : 'An error occurred';
//         toast.error(errorMessage, {
//           position: "top-right",
//           autoClose: 4000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       });
//   };



//   return (
//     <>
//       <div>
//         <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
//         <Header screenTitle="Admins List" />
//         <Box>
//           <ToastContainer />
//           <div style={{ display: 'flex', alignItems: 'end', justifyContent: "end", width: "100%", margin: "0px -40px" }}>
//             <SearchInput data={data} setData={setdata} originalData={Getdata} />
//             <AddButton path="/dashboard/createadmin" />
//           </div>


//           {filteredData.length === 0 && !searchError && (
//             <Table data={data} flag={flag} filteredData={filteredData} currentPage={currentPage} cols={[
//               {
//                 name: "Name",
//                 key: "firstname"
//               },
//               {
//                 name: "Email",
//                 key: "email"
//               },
//               {
//                 name: "Action",
//                 displayFeild: (e) => (
//                   <>
//                     <EditButton text="Edit" click={() => edit(e)} />
//                     <DeleteButton text="Delete" click={() => DeleteRow(e)} />
//                   </>
//                 )
//               },
//             ]}
//             />
//           )}
//           {searchError && <p>No results found</p>}
//         </Box>
//         <div style={{ display: 'flex', justifyContent: 'center' }}>
//           <nav aria-label="Page navigation example">
//             <ul className="pagination justify-content-center gap-2  ">
//               <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                 <button
//                   className="page-link"
//                   style={{ color: "black" }}
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 >
//                   Previous
//                 </button>
//               </li>
//               {Array.from({ length: totalPages }, (_, index) => (
//                 <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
//                   <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{ backgroundColor: "#212529", border: "1px solid #212529", borderRadius: "5px", color: "white" }}>
//                     {index + 1}
//                   </button>
//                 </li>
//               ))}

//               <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                 <button
//                   className="page-link"
//                   style={{ color: "black" }}
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 >
//                   Next
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//       <Copyright />
//     </>
//   )
// }

// export default ListAdmin


import { Box } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Header from '../../Components/Header';
import MainHeader from '../../Components/MainHeader';
import Table from '../../Components/Table';
import { Delete, GetThePage } from '../../Config/ApiHandling';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SearchInput from '../../Components/SearchInp';
import AddButton from '../../Components/AddButton';
import EditButton from '../../Components/EditButton';
import DeleteButton from '../../Components/DeleteButton';
import Copyright from '../../Components/Copyright';

const ListAdmin = ({ setCollapsed, collapsed }) => {
  // let [open, setopen] = useState(false);
  let [data, setData] = useState([]);
  let [flag, setFlag] = useState(false);
  let navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // totalPages should be a number

  const token = localStorage.getItem('token');

  const Getdata = useCallback(() => {
    setFlag(true);
    const limit = 10;

    GetThePage('admin/getuserbytype', 'admin', currentPage, limit, token)
      .then((res) => {
        setData(res.data.data.users);
        setTotalPages(res.data.data.totalPages);
      })
      .catch((e) => {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'An error occurred';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .finally(() => {
        setFlag(false);
      });
  }, [currentPage, token]);

  useEffect(() => {
    Getdata();
  }, [Getdata]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const edit = (e) => {
    navigation(`/dashboard/editadmin/${e._id}`, {
      state: e,
    });
  };

  const DeleteRow = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then((result) => {
        if (result.isConfirmed) {
          Delete('/admin/delete', e._id, token)
            .then((res) => {
              Getdata();
              let filter = data.filter((x) => x._id !== e._id);
              setData([...filter]);
            })
            .catch((error) => {
              const errorMessage =
                error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : 'An error occurred';
              toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            });
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred';
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfWay = Math.floor(maxButtonsToShow / 2);

    let startPage, endPage;

    if (totalPages <= maxButtonsToShow) {
      // If total pages are less than max buttons to show, show all pages
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= halfWay) {
      // If current page is near the start, show the first few pages
      startPage = 1;
      endPage = maxButtonsToShow;
    } else if (currentPage + halfWay >= totalPages) {
      // If current page is near the end, show the last few pages
      startPage = totalPages - maxButtonsToShow + 1;
      endPage = totalPages;
    } else {
      // Otherwise, center the current page in the range
      startPage = currentPage - halfWay;
      endPage = currentPage + halfWay;
    }

    // Generate page buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            style={{
              backgroundColor: currentPage === i ? "white" : "#212529",
              border: "1px solid #212529",
              borderRadius: "5px",
              color: currentPage === i ? "black" : "white"
            }}
          >
            {i}
          </button>
        </li>
      );
    }

    return buttons;
  };

  return (
    <>
      <div>
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Header screenTitle="Admins List" />
        <Box>
          <ToastContainer />
          <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', width: '100%', margin: '0px -40px' }}>
            <SearchInput data={data} setData={setData} originalData={Getdata} />
            <AddButton path="/dashboard/createadmin" />
          </div>
          <Table
            data={data}
            flag={flag}
            currentPage={currentPage}
            cols={[
              {
                name: 'Name',
                key: 'firstname',
              },
              {
                name: 'Email',
                key: 'email',
              },
              {
                name: 'Action',
                displayFeild: (e) => (
                  <>
                    <EditButton text="Edit" click={() => edit(e)} />
                    <DeleteButton text="Delete" click={() => DeleteRow(e)} />
                  </>
                ),
              },
            ]}
          />
          {false && <p>No results found</p>}
        </Box>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-2">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  style={{ color: 'black' }}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(index + 1)} style={{
                    backgroundColor: currentPage === index + 1 ? "white" : "#212529",
                    border: "1px solid #212529",
                    borderRadius: "5px",
                    color: currentPage === index + 1 ? "black" : "white"
                  }}>
                    {index + 1}
                  </button>
                </li>
              ))} */}
              {renderPaginationButtons()}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  style={{ color: 'black' }}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default ListAdmin;
