import React, { useState } from "react";
import { SearchByName, SearchByNameByType } from "../Config/ApiHandling";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const SearchInput = ({ setData, originalData, msg, endpoint }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("token");
  const name = useParams();

  const handleSearch = (e) => {
    e.preventDefault();
    if (msg === "This_is_not_typ_case") {
      if (searchTerm.trim() !== "") {
        SearchByName(
          `${endpoint}`,
          searchTerm,
          token
        )
          .then((res) => {
            const searchData = res.data.data;
            const searchDataArray = Array.isArray(searchData)
              ? searchData
              : [searchData];
            setData(searchDataArray);
          })
          .catch((error) => {
            if (error.status === 404 || error.status === undefined) {
              toast.error(`${searchTerm} does not exist`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
              });
              return;
            }
          });
      } else {
        setData(originalData());
      }




    } else {
      if (searchTerm.trim() !== "") {
        SearchByNameByType(
          "admin/search",
          searchTerm,
          name["*"] === "userlist"
            ? "user"
            : name["*"] === "partnerlist"
              ? "partner"
              : name["*"] === "clientlist"
                ? "client"
                : "admin",
          token
        )
          .then((res) => {
            const searchData = res.data.data.users;
            const searchDataArray = Array.isArray(searchData)
              ? searchData
              : [searchData];
            setData(searchDataArray);
          })
          .catch((error) => {
            if (error.status === 404 || error.status === undefined) {
              toast.error(`${searchTerm} does not exist`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
              });
              return;
            }
          });
      } else {
        setData(originalData());
      }
    }

  };

  const handleBlur = () => {
    if (searchTerm.trim() === "") {
      setData(originalData);
    }
  };

  return (
    <div>
      <ToastContainer />
      <form className="d-flex" style={{ justifyContent: "end", marginRight: "30px" }}>
        <input
          className="form-control me-2 w-100"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onBlur={handleBlur}
        />
        <button
          className="btn btn-outline-dark"
          onClick={(e) => handleSearch(e)}>
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchInput;