import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';

const BsButton = (props) => {
  const { text, isButtonDisabled, onClick } = props;
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setLoading(true);
    onClick(onClick);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <Button
        disabled={isButtonDisabled || loading}
        sx={{
          backgroundColor: '#212529',
          width: '30%',
          borderRadius: '10px',
          '&:hover': { backgroundColor: 'white', color: '#212529' },
        }}
        variant="contained"
        type='button'
        onClick={handleButtonClick}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : text}
      </Button>
    </>
  );
};

export default BsButton;  